var markers = [];
function highlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  } else {
    markers[i].setAnimation(google.maps.Animation.BOUNCE);
  }
}
function stopHighlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  }
}
function initContactUsMap2(){
    var lCount;
    var sum1 = 0;
    var sum2 = 0;
    for (lCount = 0; lCount < locations.length; lCount++) {
        sum1 = sum1 + locations[lCount][1];
        sum2 = sum2 + locations[lCount][2];
    }
    var center1 = sum1 / lCount;
    var center2 = sum2 / lCount;

    //var myLatlng = new google.maps.LatLng(47.774149390394136, 10.175040898214547);
    var myLatlng = new google.maps.LatLng(center1, center2);
    var mapOptions = {
            zoom: 3,
            center: myLatlng,
            styles: [
                {"featureType":"water","elementType":"geometry","stylers":[{"color":"#E6EEF8"},{"lightness":17}]},
                //{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#d5d5d5"},{"lightness":20}]},
                //{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},
                //{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},
                //{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},
                //{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},
                //{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},
                //{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},
                //{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},
                //{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},
                //{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},
                //{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},
                //{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},
                //{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}
            ],
            scrollwheel: false,
        }
    var map = new google.maps.Map(document.getElementById("contactUsMap2"), mapOptions);

    var infowindow = new google.maps.InfoWindow();

    var marker, i;
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            map: map
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }
    $(".notmap").hover(function() {
        highlightMarker($(this).data('value'));
        }, function() {
        stopHighlightMarker($(this).data('value'));
    });
}
function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}
$(document).ready(function(){

    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}
    $('.parallax-window').parallax();
    if($('.topSlider').length){
        $('body').addClass('hasSlider');
    }
    if($('#contactUsMap2').length){
        initContactUsMap2();
    }

});
$(window).on("load", function (e) {

    $("table").addClass('table table-bordered table-hover');
    if(window.validate_contact_form){
		validate_contact_form();
		add_js_input('#contact_form');
	}

    $(document).on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox({ wrapping: false });
    });

})
//$(window).load(function() {

//});